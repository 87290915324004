import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {add} from "./sentSlice";

export function Sent() {
    const count = useSelector((state)=> state.sent.value)
  const dispatch = useDispatch()
  return (
    <div>
        <button onClick={()=>dispatch(add())}>
        Add a new word
        </button>
        <div>Sent</div>
        {count}
    </div>
  )
}
