import { configureStore } from '@reduxjs/toolkit'
import counterReducer from "../features/counter/counterSlice"
import sentReducer from "../features/sent/sentSlice"


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sent: sentReducer,
  },
})

