import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { increment } from "./counterSlice";

export function Counter() {
  const count = useSelector((state) => state.counter.value);
  const dispatch = useDispatch();

  return (
    <div>
      <button onClick={() => dispatch(increment())}> Increment </button>
      {count}
      <div>
        <div></div>
      </div>
    </div>
  );
}
