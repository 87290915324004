import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: "Nothing"
}


const sentSlice = createSlice({
    name: "sent",
    initialState, 
    reducers:
    {  
    add: (state)=>{state.value = state.value+ "something"}

    }
}
)

export const {add} = sentSlice.actions
export default sentSlice.reducer